angular.module('baseapp.filters', [])

.filter('orderObjectBy', function() {
    return function(items, field) {
        // Check if orderBy field exists and return filtered array
        if(items[Object.keys(items)[0]][field]) {
            var filtered = [];
            angular.forEach(items, function(item) {
                filtered.push(item);
            });
            filtered.sort(function (a, b) {
                return (a[field] > b[field] ? 1 : -1);
            });
            return filtered;
        }
        return items;
    };
})
.filter('getMessage', function () {
    return function (message) {
        if(angular.isArray(message)) {
            var m = [];
            for (var i = 0; i < message.length; ++i) {
                angular.forEach(message[i], function(value, key) {
                    m.push(value);
                });
            }
        }
        return m ? m.join('<br>') : message;
    };
})
.filter('rPad', function () {
    return function (string, limit, padString) {
        while(string.length < limit)
            string += padString;
        return string;
    };
})

.filter('arrayDiff', function() {
    return function(array1, array2) {
        return array1.filter(function(obj) {
            return !array2.some(function(obj2) {
                return obj.key == obj2.key;
            });
        });
    };
})
    
//Returns the filtered array based on search key and value
.filter('arrayFilterCond', function() {
    return function(items, key, value) {
        if (key === undefined || value === undefined) {
            return items;
        }
        var output = [];
        angular.forEach(items, function (item) {
            if (item[key] == value) {
                output.push(item);
            }
        });
        return output;
    };
})
;
