'use strict';

// include ngFileUpload module only for betman
baseappControllers.requires.push('ngFileUpload');

angular.module('baseapp.controllers')

    .controller('DocumestCtrl', ['$window', '$http', '$timeout', function ($window, $http, $timeout) {

        var self = this;

        self.viewer = {};
        self.container = null;

        self.files = [];
        self.selectedIndex = null;

        self.init = function (win, winId) {
            self.files = win.data.files || [];

            self.initPdfViewConfig(win, winId);
            self.initFirstFile(win);
        };

        self.initPdfViewConfig = function (win, winId) {
            self.container = angular.element(document.querySelector('#win-' + winId + ' .js-documest-view'));
        };

        self.initFirstFile = function (win) {
            var file = win.data.files && win.data.files.length > 0 ? win.data.files[0] : null;

            if (file) {
                self.openFileContent(file);
            } else {
                self.showError(win);
            }
        };

        self.openFileContent = function (file) {
            self.selectedIndex = file.id;
            self.loadFileContent(file)
        };

        self.loadFileContent = function (file) {
            switch (file['mimetype']) {
                case 'image/png':
                case 'image/jpeg':
                    self.openImageFile(file);
                    break;
                case 'text/xml':
                    self.openXmlFile(file);
                    break;
                case 'application/json':
                    self.openJsonFile(file);
                    break;
                case 'application/pdf':
                    self.openPdfFile(file);
                    break;
                case 'text/plain':
                default:
                    self.openTextFile(file);
                    break;
            }
        };

        self.showError = function (win) {
            var errorElem = angular.element('<span></span>')
                .text(win.translations.noFileError || 'Error');
            self.container.append(errorElem);
        };

        self.blobToFileUrl = function (file) {
            return ['data:', file.mimetype, ';base64,', file.content].join('');
        };

        self.appendContent = function (content) {
            if (self.files.length > 1) {
                self.container.addClass('documest__view--multi-files')
            }

            self.viewer = {};
            self.container.empty();
            self.container.append(content);
        };

        self.openImageFile = function (file) {
            var imgElem = angular.element('<img>')
                .attr('src', self.blobToFileUrl(file));

            self.appendContent(imgElem);
        };

        self.openPdfFile = function (file) {
            var pdfData = angular.element('<input>')
                .addClass('pdf-hidden-data')
                .attr('type', 'hidden')
                .attr('value', self.blobToFileUrl(file));
            self.appendContent(pdfData);

            var pdfElem = angular.element('<iframe>')
                .addClass('documest__pdf-embed')
                .attr('name', 'pdfIFrame')
                .attr('src', 'pdfjs/web/viewer.html');

            if (self.files.length > 1) {
                pdfElem.addClass('documest__pdf-embed--multi-files');
            }

            self.container.append(pdfElem);
        };

        self.openXmlFile = function (file) {
            var content = $window.atob(file.content);

            var preElem = angular.element('<pre></pre>');
            preElem.addClass('documest__code');
            preElem.text(content);
            self.appendContent(preElem);
        };

        self.openJsonFile = function (file) {
            var jsonString = $window.atob(file.content);

            var content = JSON.stringify(JSON.parse(jsonString), undefined, 4);

            var preElem = angular.element('<pre></pre>');
            preElem.addClass('documest__code');

            preElem.text(content);
            self.appendContent(preElem);
        };

        self.openTextFile = function (file) {
            var spanElem = angular.element('<div></div>')
                .addClass('text')
                .text($window.atob(file.content));

            self.appendContent(spanElem);
        };
    }]);
