'use strict';

/* Controllers */

var baseappControllers = angular.module('baseapp.controllers', [])

    .controller('AppCtrl', ['Grid', 'WinFn', 'Wins', '$http', '$templateCache', 'Ajax', '$mdToast', '$mdDialog', function(Grid, WinFn, Wins, $http, $templateCache, Ajax, $mdToast, $mdDialog) {
        var self = this;
        self.grid  = Grid;
        self.winFn = WinFn;
        self.wins  = Wins;

        Ajax.call('site/get-maintenance-mode', {toggle: false}, function (response) {
            self.maintenanceModeValue = response.maintenance_mode == 'true' ? true : false;
        });

        var templates = [
            './partials/win/content/permissions/create',
            './partials/win/content/permissions/view',
            './partials/win/content/create',
            './partials/win/content/cp-table',
            './partials/win/content/view',
            './partials/win/content/table/contenteditable',
            './partials/win/content/table/link',
            './partials/win/content/table/md-autocomplete',
            './partials/win/content/table/md-icon-button',
            './partials/win/content/table/md-icon-button-safe-accept',
            './partials/win/content/table/md-select',
            './partials/win/content/table/md-switch',
            './partials/win/content/table/md-checkbox',
            './partials/win/content/table/md-icon',
            './partials/win/content/table/inline-action',
            './partials/win/content/table/text',
            './partials/win/content/table/cp-autocomplete',
            './partials/win/content/table/cp-editable',
            './partials/win/content/table/cp-switch',
            './partials/win/content/table/cp-datetime-picker',
            './partials/win/content/table/menu',
            './partials/win/content/table/menu-freeze',
            './partials/win/content/table/menu-unfreeze',
            './partials/win/content/table/menu-mass-update',
            './partials/win/content/table/menu-checkbox',
            './partials/win/content/table/menu-bulk-accept',
            './partials/win/content/table/menu-help',
            './partials/win/content/table/number-no-fraction',
            './partials/win/content/table/number-no-fraction-percent',
            './partials/win/sidenav/tree',
            './partials/win/form/md-input',
            './partials/win/form/md-select',
            './partials/win/form/cp-datetime-picker',
            './partials/win/form/text',
            './partials/win/form/md-autocomplete',
            './partials/win/form/update/md-input',
            './partials/win/form/update/md-switch',
            './partials/win/search',
            './partials/win/share',
            './partials/win/mass-update',
            './partials/win/mass-update-cp-switch',
            './partials/win/dialog-form',
            './partials/md-dialog'
        ];
        setTimeout(function() {
            templates.forEach(function(template) {
                $http.get(template, {cache:$templateCache});
            });
        }, 1000);

        self.clearCache = function(event) {
            var confirm = $mdDialog.confirm()
                .title('Are you sure?')
                .textContent('This action is going to clear entire application cache!')
                .targetEvent(event)
                .theme('modal')
                .ok('Clear')
                .cancel('Cancel');

            $mdDialog.show(confirm)
                .then(function() {
                        Ajax.call('site/clear-cache', function(response) {
                            $mdToast.show($mdToast.simple().position('top right').content(response.message));
                        });
                    },function() {}
                );
        };

        self.toggleMaintenanceMode = function(event) {
            var maintenanceMode = this.maintenanceModeValue;
            var actionMessage = maintenanceMode ?
                'Disabling maintenance mode will resume operations for all terminals. Ensure all maintenance tasks are completed before proceeding.' :
                'Enabling maintenance mode will temporarily halt operations for all terminals!';
            var actionButton = maintenanceMode ? 'Disable' : 'Enable';

            var confirm = $mdDialog.confirm()
                .title('Are you sure?')
                .textContent(actionMessage)
                .targetEvent(event)
                .theme('modal')
                .ok(actionButton)
                .cancel('Cancel');

            $mdDialog.show(confirm)
                .then(function() {
                    Ajax.call('site/toggle-maintenance-mode', {toggle: true}, function (response) {
                        self.maintenanceModeValue = response.maintenance_mode == 'true' ? true : false;
                        var responseMessage = self.maintenanceModeValue ? 'Maintenance mode enabled' : 'Maintenance mode disabled';
                        $mdToast.show($mdToast.simple().position('top right').textContent(responseMessage));
                    })
                })
        };

        //Open window from url
        function getQueryVariable(variable)
        {
            var query = window.location.search.substring(1),
                vars = query.split('&');
            for(var i = 0; i < vars.length; i++) {
                var pair = vars[i].split('=');
                if(pair[0] === variable)
                    return pair[1];
            }
            return false;
        }

        var newWin = getQueryVariable('w');
        var _pageLimit = getQueryVariable('limit');
        var _pageNumber = getQueryVariable('page');

        if(newWin)
            self.winFn.create({url: newWin, position: 'position-center', pageLimit : _pageLimit, pageNumber: _pageNumber});


        // Page leave confirmation message, get translated text from hidden element
        window.onbeforeunload = function() {
            if (typeof disableOnBeforeUnload === 'undefined' || !disableOnBeforeUnload) {
                var message = document.getElementById('page-leave-confirmation');
                return message.textContent;
            }
        };



        self.getScrollbarWidth = function() {
            if(!self.scrollbarWidth) {
                var outer = document.createElement('div');
                outer.style.visibility = 'hidden';
                outer.style.width = '100px';

                document.body.appendChild(outer);

                var widthNoScroll = outer.offsetWidth;

                // force scrollbars
                outer.style.overflow = 'scroll';

                // add innerdiv
                var inner = document.createElement('div');
                inner.style.width = '100%';
                outer.appendChild(inner);

                var widthWithScroll = inner.offsetWidth;

                // remove divs
                outer.parentNode.removeChild(outer);

                self.scrollbarWidth = widthNoScroll - widthWithScroll;
            }

            return self.scrollbarWidth + 'px';
        };

        // this will be encapsulated and isolated from the rest of the stuff
        (function() {
            var _interval = setInterval(function () {
                var _fields = document.querySelectorAll('input[ng-required="$mdAutocompleteCtrl.isRequired"]');

                if(_fields !== null && _fields.length) {
                    _fields.forEach(function (_field) {
                        _field.setAttribute("aria-autocomplete", "none");
                        _field.setAttribute("role", "presentation");
                        _field.setAttribute("autocomplete", "field-1597503468208");
                    });

                    clearInterval(_interval);
                }
            }, 1000);
        })();

    }])

    .controller('SearchCtrl', ['Ajax', '$q', '$filter', function(Ajax, $q, $filter) {
        var self            = this;
        self.readonly       = false;
        self.selectedItem   = null;
        self.searchText     = null;
        self.lastSearchText = null;
        self.deferred       = $q.defer();
        self.results        = null;
        self.arrayResults   = [];

        self.querySearch    = function(url, query, filter) {

            self.deferred = $q.defer();

            if(query && query.length >= 2) {

                Ajax.call(url, { search: query }, function(response) {

                    if(Array.isArray(filter) && filter.length) {
                        response.data = $filter('arrayDiff')(response.data, filter)
                    }

                    self.deferred.resolve(response.data);
                    return self.deferred.promise;
                });
            }

            return self.deferred.promise;
        };

        self.endQuerySearch = function() {
            self.searchText  = null;
            self.deferred.resolve([]);
        };

        self.onKeydown = function(e) {
            // prevent enter key
            if(e.keyCode === 13) {
                e.stopPropagation();
                e.preventDefault();

                //clean up search input
                self.endQuerySearch();
            }
        };

        self.filterItems = function(items) {
            if(!self.searchText)
                return items;

            var filteredItems = [],
                matcher       = new RegExp(self.searchText, 'i'),
                isMatched     = function(item) {
                    return matcher.test(item.value);
                };

            filteredItems = items.filter(isMatched);

            return filteredItems;
        }

        self.searchTextChange = function(field) {
            self.results = self.querySearch(field.url, self.searchText, field.value);
            self.lastSearchText = self.searchText;
        }

        self.selectedItemChange = function() {
            self.searchText = self.lastSearchText;
        }

    }])

    .controller('TableCtrl', ['$scope', 'Ajax', 'WinFn', '$mdDialog', '$interval', function($scope, Ajax, WinFn, $mdDialog, $interval) {
        var self = this;

        self.minColumnWidth = 160;

        self.getTableWidth = function(cols, colsHidden) {
            var width = 0;
            for(var i = 0; i < cols.length; i++) {
                if(!colsHidden[i])
                    width += cols[i].width ? parseInt(cols[i].width) : self.minColumnWidth;
            }

            return width;
        };

        self.setOldValues = function(data) {
            angular.forEach(data.rows, function(row) {
                angular.forEach(data.columns, function(col) {
                    row[col.key + 'Old'] = angular.copy(row[col.key]);
                });
            });
        };

        self.updateField = function(win, row, col, value, valueOld) {
            //fix for md-select when is multiple set -> update field should not be executed on init
            if(!WinFn.search.valueChanged(value,valueOld) && !win.data.fields)
                return;

            if(win.data.fields && win.data.fields[row] && win.data.fields[row][col] && !WinFn.search.valueChanged(value,valueOld)) {
                delete win.data.fields[row][col];

                if(!Object.keys(win.data.fields[row]).length) {
                    delete win.data.fields[row];
                }

                if(!Object.keys(win.data.fields).length) {
                    delete win.data.fields;
                }

            } else {
                if(!win.data.fields) {
                    win.data.fields = {};
                }

                if(!win.data.fields[row]) {
                    win.data.fields[row] = {};
                }

                win.data.fields[row][col] = value === undefined ? null : value;
            }
        };

        self.selectRow = function(win,value) {

            if(!win.data.selectedRows) {
                win.data.selectedRows = [];
            }

            var key = win.data.selectedRows.indexOf(value);
            if (key > -1) {
                win.data.selectedRows.splice(key, 1);
            } else {
                win.data.selectedRows.push(value);
            }
        };

        self.selectAll = function(data,col) {

            if(!data.selectedRows) {
                data.selectedRows = [];
            }

            if (data.selectedRows.length > 0) {
                data.selectedRows = [];
            } else if (data.selectedRows.length === 0 || data.selectedRows.length > 0) {

                var rowsLength = data.rows.length;
                for(var i = 0; i < rowsLength;i++) {
                    var disabled = self.checkDisabled(data.rows[i],col);
                    if(data.selectedRows.indexOf(data.rows[i][col.key]) == -1 && !disabled) {
                        data.selectedRows.push(data.rows[i][col.key]);
                    }
                }
            }
        };

        self.isSelected = function(data,value) {
            if(!data.selectedRows) {
                data.selectedRows = [];
            }

            return data.selectedRows.indexOf(value) > -1 ;
        };

        self.checkDisabled = function(row,col) {
            if (col.checkConditions !== undefined) {
                for (var key in col.checkConditions) {
                    var disabled = row[key] === col.checkConditions[key];
                    if (disabled) { //If only one condition is met, return right away
                        return true;
                    }
                }
            }
            return false;
        };

        self.newRow = 0;
        self.addRow = function(win) {
            if(!win.data.fields.new) {
                win.data.fields.new = {};
            }

            var id = 'n'+self.newRow++;

            win.data.fields.new[id] = {
                id : id
            };
        };

        self.delete = function(win, row) {

            if(win.data.fields.new && win.data.fields.new[row.id]) {
                delete win.data.fields.new[row.id];
                return;
            }

            if(!win.data.fields.delete) {
                win.data.fields.delete = [];
            }

            if(win.data.fields.delete.indexOf(row.id) === -1) {
                win.data.fields.delete.push(row.id);
            }
        };

        self.loadSelectItems = function(url, field) {
            Ajax.call(url, function(response) {
                field.items = response.data;
            });
        };

        self.autoUpdate = function(win, interval) {
            self.update = function() {
                // if win is closed url will be set to null, no need for further autoupdate
                if(win.url) {
                    win.updateAfterWait = null; // reset update after wait
                    // stop the interval and wait for the response of previous request
                    if (win.ajaxInProgress) {
                        if (win.jsinterval && win.jsinterval.$$state.status == 0) {
                            $interval.cancel(win.jsinterval);
                        }
                        if (!win.progress) { // if we have win in progress don't add callback
                            // function that will be executed after waiting for the response if interval was stopped
                            win.updateAfterWait = self.update;
                        }
                        return false;
                    }
                    if (win.jsinterval && win.jsinterval.$$state.status == 2) { // status 2 means interval is canceled
                        win.jsinterval = $interval(self.update, interval * 1000);
                    }

                    Ajax.call(
                        win.url,
                        {
                            searchFields : win.data.search ? win.data.search.values : false,
                            sort         : win.data.sort,
                            page         : win.data.page,
                            pageLimit    : win.data.pageLimit
                        },
                        function(response) {
                            if(!win.data)
                                win.data = {};

                            ['rows', 'message', 'message_short','count'].forEach(function(attr) {
                                if(attr === 'url') {
                                    win.url = response.url;
                                } else {
                                    win.data[attr] = response[attr];
                                }
                            });
                        },
                        self.update,
                        win
                    );
                } else {
                    $interval.cancel(win.jsinterval); // cancel interval if there is no win.url
                }
            };
            if (win.jsinterval) {
                $interval.cancel(win.jsinterval); // cancel interval because new request is made
            }
            win.jsinterval = $interval(self.update, interval * 1000); // start autoupdate interval
        };

        self.showMassUpdateDialog = function(col,win) {
            $mdDialog.show({
                templateUrl : './partials/win/mass-update' + (typeof col.massUpdate === 'string' ? '-' + col.massUpdate : ''),
                controller  : ['$scope', function($scope) {
                    $scope.col   = col;
                    $scope.value = null;

                    $scope.submit = function() {
                        for(var i = 0; i < win.data.rows.length; i++) {
                            self.updateField(win,win.data.rows[i]['id'], col.key, $scope.value, win.data.rows[i][col.key + 'Old']);
                            win.data.rows[i][col.key] = $scope.value;
                        }
                        $scope.close();
                    };

                    $scope.close = function() {
                        $mdDialog.hide();
                    };
                }]
            });
        };

        self.safeAccept = function(col, win, id) {
            win.progress = true;

            var url    = col.link + (id ? '/' + id : ''),
                pk     = col.pk ? col.pk : 'id',
                params = {
                    searchFields : win.data.search.values,
                    sort         : win.data.sort,
                    pageLimit    : win.data.pageLimit,
                    data         : []
                };

            if(!id) {
                win.data.rows.forEach(function(row) {
                    if(!row[col.key]) {
                        params.data.push(row[pk]);
                    }
                });

                url = col.bulkAccept;
            }

            Ajax.call(url, params, function(response) {
                win.data     = response;
                win.url      = response.url;
                win.progress = false;
            });
        };

        self.getMenu = function(win, colIndex) {
            var templatePath = './partials/win/content/table/';
            win.data.columns[colIndex].menuItems = [];

            if (!win.colSettings.fixed[colIndex] && (colIndex === 0 || win.colSettings.fixed[colIndex - 1]))
                win.data.columns[colIndex].menuItems.push(templatePath + 'menu-freeze');

            if (win.colSettings.fixed[colIndex] && !win.colSettings.fixed[colIndex + 1])
                win.data.columns[colIndex].menuItems.push(templatePath + 'menu-unfreeze');

            if (win.data.columns[colIndex].massUpdate && win.data.rows.length)
                win.data.columns[colIndex].menuItems.push(templatePath + 'menu-mass-update');

            if (win.data.columns[colIndex].type === 'md-checkbox' && win.data.rows.length)
                win.data.columns[colIndex].menuItems.push(templatePath + 'menu-checkbox');

            if (win.data.columns[colIndex].help)
                win.data.columns[colIndex].menuItems.push(templatePath + 'menu-help');

            if (win.data.columns[colIndex].bulkCopy)
                win.data.columns[colIndex].menuItems.push(templatePath + 'menu-bulk-copy');

            if (win.data.columns[colIndex].bulkAccept)
                win.data.columns[colIndex].menuItems.push(templatePath + 'menu-bulk-accept');

            return win.data.columns[colIndex].menuItems.length ? (win.data.columns[colIndex].menuItems.length > 1 ? templatePath + 'menu' : win.data.columns[colIndex].menuItems[0]) : false;
        };

        self.dialog = function(win,row,col) {
            $mdDialog.show({
                templateUrl : './partials/win/dialog-form',
                controller  : ['$scope', function($scope) {
                    $scope.row       = row;
                    $scope.fields    = col.fields;
                    $scope.directive = col.directive;

                    angular.forEach($scope.fields, function(value, key) {
                        $scope.fields[key].value = row[key];
                    });

                    $scope.submit = function() {
                        $scope.progress = true;

                        var params = {
                            id : row.id
                        };

                        angular.forEach($scope.fields, function(field) {
                            params[field.attribute] = field.value;
                        });

                        Ajax.call(col.submitUrl, params, function(response) {
                                $scope.progress = false;
                                if(response.errors && response.errors.length) {
                                    $scope.errors = response.errors;
                                } else {
                                    $mdDialog.hide();
                                }
                            }
                        );
                    };

                    $scope.close = function() {
                        $mdDialog.hide();
                    };
                }]
            });

        };

        self.bulkCopy = function(win, col) {
            win.data.timeout = win.data.timeout || 10;
            win.data.rows.forEach(function(row) {
                if (row[col.bulkCopy] && !row[col.key]) {
                    self.updateField(win, row.id, col.key, row[col.bulkCopy], row[col.key]);
                    row[col.key] = row[col.bulkCopy];
                    win.data.timeout++;
                }
            });
        };

        $scope.$on('$destroy', function() {
            if(self.ticker) {
                clearTimeout(self.ticker);
            }
        });
    }])

    .controller('CreateCtrl', function() {
        var self = this;

        self.selectAll = function(field) {
            field.value = [];
            angular.forEach(field.items, function(item) {
                field.value.push(item.key);
            });
        };

        self.deselectAll = function(field) {
            field.value = null;
        };

        return self;
    })

    .controller('ToastShareCtrl', ['$scope', '$mdToast', 'url', function($scope, $mdToast, url) {
        // url variable is injected in service winFn.share
        $scope.getUrl = function(hostUrl, baseUrl) {
            return hostUrl + '/?w=' + url.replace(new RegExp(baseUrl + '/?'), '');
        };

        $scope.close = function() {
            $mdToast.hide();
        };
    }])

    .controller('DeleteCustomerCtrl', ['$scope', 'WinFn', 'Ajax', function($scope, WinFn, Ajax) {
        var self = this;

        self.getActionUrl = function(win) {
            return '/support/customer/delete/' + win.data.data.customer.id + '?d=' + win.data.digest;
        };

        $scope.consentChecked = false;
        $scope.deletionStarted = false;
        $scope.deletionReason = '';

        self.submitForm = function(e) {
            e.preventDefault();

            if (confirm('Are you 100% sure you want to continue with this?\n\nThis process cannot be reversed!')) {
                $scope.deletionStarted = true;
                var action = e.target.getAttribute('action');

                Ajax.call(action, {
                    consent: !!document.getElementById('consent').checked,
                    reason: document.getElementById('deletion_reason').value,
                }, function(data) {
                    console.log(data);
                });
                // Handle form submission logic here
                // Access the form data using $scope.deletion_reason and $scope.consentChecked
            }
        };

        return self;
    }])
;
