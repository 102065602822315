'use strict';

// Declare app
angular.module('baseapp', [
    'baseapp.services',
    'baseapp.directives',
    'baseapp.filters',
    'baseapp.controllers',
    'ngSanitize',
    'ngMessages',
    'ngMaterial',
    'ngMaterialDatePicker',
    'flow'
])

.config(['$provide', '$httpProvider', '$mdThemingProvider', 'flowFactoryProvider',
    function($provide, $httpProvider, $mdThemingProvider, flowFactoryProvider) {
    $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    $httpProvider.defaults.headers.common['X-CSRF-Token'] = document.getElementsByTagName('meta')['csrf-token']['content'];

    $mdThemingProvider.theme('default')
        .primaryPalette('yellow')
        .primaryPalette('yellow', {
            'default': '600' // by default use shade 600 from the yellow palette for primary intentions
        })
        .accentPalette('grey', {
            'default': '800' // by default use shade 800 from the grey palette for accent intentions
        });

    // confirm modal theme
    $mdThemingProvider.theme('modal')
        .primaryPalette('grey', {
            'default': '900'
        })
        .accentPalette('grey', {
            'default': '600'
        });

    // flow error theme
    $mdThemingProvider.theme('support-error');

    flowFactoryProvider.defaults = {
        target: '/',
        permanentErrors: [415, 500, 501],
        maxChunkRetries: 1,
        chunkRetryInterval: 5000,
        simultaneousUploads: 1
    };

    flowFactoryProvider.on('catchAll', function (event) {
        console.log('catchAll', arguments);
    });
}])
;