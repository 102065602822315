'use strict';

angular.module('baseapp.controllers')

    .controller('AppVersionUploadCtrl', ['$scope', '$mdToast', 'WinFn', function ($scope, $mdToast, WinFn) {

        var self = this;
        self.win = {};
        self.urlIndex = '';
        self.version = {};
        self.maxFileSize = 1;
        self.selectedFile = 'SELECT A FILE';
        self.showProgress = false;
        self.activatedSave = true;
        self.fileError = false;
        self.message = 'no message';

        self.init = function (win) {
            self.win = win;
            self.urlIndex = win.data.urlIndex || '';
            self.version = win.data.fields.version || {};
            self.maxFileSize = win.data.maxUploadSize;
        };

        self.validFile = function (flowFile) {
            if (flowFile.size > 1024*1024*self.maxFileSize) {

                $mdToast.show($mdToast
                    .simple()
                    .position('top right')
                    .content('FILE TOO BIG. Max ' + self.maxFileSize + ' MB')
                    .theme('support-error')
                    .hideDelay(3000));

                return false;
            }

            if (flowFile.getExtension() != 'zip' && flowFile.getExtension() != 'ZIP') {

                $mdToast.show($mdToast
                    .simple()
                    .position('top right')
                    .content('INVALID EXTENSION. Must be zip')
                    .theme('support-error')
                    .hideDelay(3000));

                return false;
            }

            return true;
        }

        $scope.$on('flow::fileAdded', function (event, $flow, flowFile) {
            $flow.opts.query = { versionString: self.version.value };
            self.showProgress = false;
        });

        $scope.$on('flow::filesSubmitted', function (event, $flow, flowFiles) {
            self.selectedFile = $flow.files.length ? $flow.files[0].name : 'SELECT A FILE';
        });

        $scope.$on('flow::uploadStart', function (event, $flow) {
            self.showProgress = true;
            self.activatedSave = false;
        });

        $scope.$on('flow::complete', function (event, $flow) {
            var toast = $mdToast
                .simple()
                .position('top right')
                .content(self.message)
                .action('OK')
                .hideDelay(0);

            $mdToast.show(toast)
                .then(function(response) {
                    WinFn.setAction({win : self.win, action : {url:self.urlIndex}});
                });
        });

        $scope.$on('flow::fileSuccess', function (event, $flow, flowFile, flowMessage) {
            if (!self.fileError) {
                self.message = String(flowMessage).replace(/["']/g, ""); // remove quotes
            }
        });

        $scope.$on('flow::fileError', function (event, $flow, flowFile, errorMessage) {
            self.fileError = true;
            self.message = 'ERROR during upload';
        });

        self.errorTooltip = function (errors) {
            if (errors) {
                var e = '';
                for (var i in errors) {
                    e += '<p>' + errors[i] + '</p>';
                }
                return e;
            }
        }
    }])
;
