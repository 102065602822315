'use strict';

/* Services */
angular.module('baseapp.services', [
    'ngResource',
    'ngCookies'
])
    .factory('Fn', ['$timeout', function ($timeout) {

        var self = this;

        self.debounce = function (func, wait, immediate, invokeApply) {
            var timeout, args, context, result;
            function debounce() {
                context = this;
                args = arguments;
                var later = function () {
                    timeout = null;
                    if (!immediate) {
                        result = func.apply(context, args);
                    }
                };
                var callNow = immediate && !timeout;
                if (timeout) {
                    $timeout.cancel(timeout);
                }
                timeout = $timeout(later, wait, invokeApply);
                if (callNow) {
                    result = func.apply(context, args);
                }
                return result;
            }
            debounce.cancel = function () {
                $timeout.cancel(timeout);
                timeout = null;
            };
            return debounce;
        };

        return self;
    }])

    .factory('Ajax', ['$resource', '$http', '$mdDialog', 'Wins', '$interval', function($resource, $http, $mdDialog, Wins, $interval) {
        var self    = this;

        /**
         * Possible usage:
         * Ajax.call(urlString, callbackFunction);
         * Ajax.call(urlString, paramsObject, callbackFunction);
         * Ajax.call(urlString, paramsObject, callbackFunction, null, winObject);
         * @param url
         * @param params
         * @param callback
         * @param errorCallback
         * @param win
         */
        self.call = function(url, params, callback, errorCallback, win) {
            // if  win.url is set to null, win is closed no need for ajax calls
            if (win && !win.url) {
                return false;
            }
            var targetObject = self
            if (win) {
                targetObject = win;
            }
            targetObject.ajaxInProgress = true;
            // Convert parameters if only url and callback passed
            if(params && typeof params === 'function' && !  callback) {
                callback =  params;
                params   = {};
            }
            var timeout = 30;
            if (params.timeout) {
                timeout = params.timeout;
            } else if (params.extraParams && params.extraParams.timeout) {
                timeout = params.extraParams.timeout;
            }
            return $resource(url,
                {},
                {
                    'save' : {
                        method  : 'POST',
                        timeout : timeout * 1000
                    }
                }
            ).save(
                // Data to submit
                params,
                // Success response, trigger callback function
                function(response) {
                    targetObject.ajaxInProgress = false;
                    if(typeof  callback === 'function') {
                        callback(response);
                    }
                    if (typeof  targetObject.updateAfterWait === 'function') {
                        targetObject.updateAfterWait();
                    }
                },
                //Error response
                function(response) {
                    targetObject.ajaxInProgress = false;
                    // Launch login form if session expired
                    if(response.status === 401) {
                        if (win.jsinterval) {
                            $interval.cancel(win.jsinterval);
                        }
                        $mdDialog.show({
                            templateUrl : './site/relogin-template',
                            controller  : ['$resource', 'Ajax', function($resource, Ajax) {
                                var $this = this;
                                $this.username = '';
                                $this.password = '';

                                $this.submit = function(reloginUrl) {
                                    if(!$this.submitted) {
                                        $this.submitted = true;

                                        $resource(reloginUrl).save(
                                            // Login credentials
                                            { username : $this.username, password : $this.password },
                                            // Success response
                                            function(response) {
                                                // Evaluate login action response
                                                if(response.message === 'success') {
                                                    $http.defaults.headers.common['X-CSRF-Token'] = response.csrf;
                                                    if(typeof errorCallback === 'function') {
                                                        errorCallback(response);
                                                    } else {
                                                        Ajax.call(url, params, callback, null, win);
                                                    }
                                                    $mdDialog.hide();
                                                } else {
                                                    $this.error = response.message;
                                                }
                                                $this.submitted = false;
                                            },
                                            //Error response
                                            function(response) {
                                                //console.log(response); // Debug other connection failure reason
                                            }
                                        );
                                    }
                                };
                            }],
                            controllerAs : 'relogin'
                        });

                    } else if(response.status === 302) {
                        if (win && win.jsinterval) {
                            $interval.cancel(win.jsinterval);
                        }
                        $resource(response.headers('X-Redirect')).save({}, function(response) {
                            if(typeof callback === 'function') {
                                callback(response);
                            }
                        });
                    } else if(response.status === 403) {
                        if (win && win.jsinterval) {
                            $interval.cancel(win.jsinterval);
                        }
                        if(typeof  callback === 'function') {
                            callback(response);
                        }
                    } else {
                        if (win && win.jsinterval) {
                            $interval.cancel(win.jsinterval);
                        }
                        if(typeof errorCallback === 'function') {
                            setTimeout(function() {
                                errorCallback(response);
                            }, win.data.errorRequestTimeout || 500);
                        }
                        // console.log(response); // Debug other connection failure reason
                    }
                }
            );
        };

        return self;
    }])

    .factory('Wins', function () {
        return [];
    })

    .factory('Grid', function () {
        return {
            placeholder  : '',
            visible  : false,
            grid     : [
                'position-top-left',
                'position-top',
                'position-top-right',
                'position-left',
                'position-center',
                'position-right',
                'position-bottom-left',
                'position-bottom',
                'position-bottom-right'
            ]
        };
    })

    .factory('WinFn', ['Ajax', 'Wins', '$timeout', '$mdToast', '$mdDialog', '$cookies', '$mdMenu', '$http', '$resource', '$interval', function(Ajax, Wins, $timeout, $mdToast, $mdDialog, $cookies, $mdMenu, $http, $resource, $interval) {
        var $this    = this,
            winId    = 0;

        $this.zIndex = 1000;

        // @param (optional) backend controller url
        // @return estimated row count based on window height OR previously saved default row count, based on url, if set
        $this.getPossibleRowsCount = function(url) {

            var rowsCount = Math.floor((window.innerHeight - 30 - 30 - 25 - 75) / 25); // (window - navbar - toolbar - table header - pager ) / row height

            if(!url) {
                return rowsCount;
            }

            var storedRowsCount = $cookies.getObject('rowsCount');

            // check do we load module over shared url and prepend path to shared url
            if(window.location.search && window.location.href.search('/?w=') != -1) {
                url = window.location.pathname + url;
            }

            if(url && storedRowsCount && storedRowsCount[url]) {
                rowsCount = storedRowsCount[url];
            } else {
                // Try to match main action (index) for actions that don't have page limit, e.g. create, update
                angular.forEach(storedRowsCount, function(value, key) {
                    if(url.indexOf(key) >= 0) {
                        rowsCount = value;
                    }
                })
            }

            return rowsCount;
        };

        // Generic
        //========================================
        $this.setAction = function(options) {
            if(options.action.url.length && options.action.url[0].includes('/delete')) {
                var section = options.win.class[2] || options.win.class[1] || 'record';

                if (!confirm('Are you sure you want to delete this ' + section + '?')) {
                    return false;
                }
            }

            if(options.event) {
                options.event.stopPropagation();

                if(options.event.button === 1) {
                    options.event.preventDefault();
                    options.action.target = 'blank';
                }

                if(options.event.button === 2) {
                    return false;
                }
            }

            if(options.id && typeof options.id === 'object') {
                options.id = options.id.value;
            }

            var id = options.action.url.indexOf('create') === -1 ? options.id : '';
            var params = {};

            if(options.action.params) {
                angular.forEach(options.action.params, function(paramValue, paramKey) {
                    angular.forEach(paramValue, function(value, key) {
                        if(!params[paramKey])
                            params[paramKey] = {};
                        params[paramKey][key] = options[value];
                    });
                });
            }

            params.pageLimit = $this.getPossibleRowsCount(options.action.url);

            if(options.action.target === 'blank') {
                $this.create({url : options.action.url + (id ? '/' + id : ''), params : params });
            }else if(options.action.target === 'external') {
                $mdMenu.destroy();
                $this.openExternal(options.action.url);
            } else {

                options.win.progress = true;
                $this.setHistory(options.win, options.action.url + (id ? '/' + id : ''), params);
                Ajax.call(options.action.url + (id ? '/' + id : ''), params, function(response) {

                    if(response.search && response.search.values) {
                        options.win.searchValues = angular.copy(response.search.values);
                    }

                    options.win.data     = response;
                    options.win.url      = response.url;
                    options.win.progress = false;

                    if(response.shareUrl)
                        options.win.shareUrl = response.shareUrl;
                });
            }
        };

        $this.openExternal = function(url) {
            window.open(url, '_blank', 'noopener');
        };

        $this.openDialog = function(url) {
            $mdDialog.show({
                controller : function() {
                    var dialog = this;

                    dialog.progress = true;

                    Ajax.call(url, function(response) {
                        dialog.title    = response.title;
                        dialog.data     = response.data;
                        dialog.progress = false;
                    });

                    dialog.close = function() {
                        $mdDialog.cancel();
                    };

                    return dialog;
                },
                controllerAs        : 'dialog',
                templateUrl         : './partials/md-dialog',
                parent              : angular.element(document.body),
                clickOutsideToClose : true
            })
        };

        $this.confirmDelete = function(options) {
            var confirm = $mdDialog.confirm()
                .title(options.confirmContent.title)
                .textContent(options.confirmContent.textContent)
                .targetEvent(options.event)
                .ok(options.confirmContent.ok)
                .cancel(options.confirmContent.cancel);

            $mdDialog.show(confirm)
                .then(function(answer) {
                    Ajax.call(
                        (options.urlDelete + '/' + options.id),
                        {confirmed: answer},
                        function(response) {
                            $this.setAction({
                                win    : options.win,
                                action : { target: 'self', url: response.url, message: response.message,},
                                event  : options.event});
                        },
                        function(response) { }
                    );
                });
        };

        // Window manipulation
        //========================================
        $this.create = function(options) {

            var idx = Wins.length;
            var params = options.params ? options.params : {};

            // Set rows per page limit and current page number
            params.pageLimit = options.pageLimit || $this.getPossibleRowsCount(options.url);
            params.page = options.pageNumber || 1;


            Wins.push({
                id              : winId++,
                visible         : true,
                zIndex          : ++$this.zIndex,
                position        : options.position ? options.position : 'position-center',
                url             : options.url,
                data            : {},
                history         : [{url:options.url, params:params}],
                searchCollapsed : true,
                searchValues    : params.searchFields ? params.searchFields : {},
                sidenav         : false,
                progress        : true,
                class           : options.url.split('/'),
                colSettings     : {
                    fixed  : [],
                    hidden : []
                }
            });

            Ajax.call(options.url, params, function(response) {
                Wins[idx].data = response;
                Wins[idx].progress = false;

                if(response.url)
                    Wins[idx].url = response.url;

                if(response.shareUrl)
                    Wins[idx].shareUrl = response.shareUrl;

                if(response.showSearch)
                    Wins[idx].searchCollapsed = false;

            }, null, Wins[idx]);
        };

        $this.reset = function(win) {
            if(win.showDeletedUsers) {
                delete win.showDeletedUsers;
            }

            win.progress = true;
            win.editor = false;

            var params = {
                sort         : win.data.sort,
                page         : win.data.page,
                pageLimit    : win.data.pageLimit,
                extraParams  : win.data.extraParams ? win.data.extraParams : {}
            };

            // Don't send empty fields
            if (win.data.search !== undefined) {
                for(var i in win.data.search.values) {
                    if(!win.data.search.values[i] || win.data.search.values[i].length === 0) {
                        delete win.data.search.values[i];
                    }
                }
                params.searchFields = win.data.search.values;
            }


            $this.setHistory(win, win.url, params);
            Ajax.call(win.url, params, function(response) {
                win.data = response;
                win.progress = false;
            }, null, win);
        };

        $this.close = function(idx) {
            // Wins.splice(idx, 1);
            Wins[idx].url = null; // set url to null to stop autoupdate requests

            delete Wins[idx];
        };

        $this.closeTab = function(idx, e) {
            if(e.button === 1) {
                e.stopPropagation();
                // Wins.splice(idx, 1);
                delete Wins[idx];
            }
        };

        $this.hideAll = function() {
            for(var i in Wins) {
                Wins[i].visible = false;
            }
        };

        $this.toggle = function(win) {
            if(win.visible && win.zIndex === $this.zIndex) {
                win.visible = false;
            } else {
                $this.focus(win);
            }
        };

        $this.goBack = function(win) {
            win.progress = true;
            win.history.pop();
            var back = win.history[win.history.length-1];

            if(win.history.length == 0) {
                if(win.showDeletedUsers) {
                    delete win.showDeletedUsers;
                }
            }

            //To go directly somewhere with back button
            if (win.data.overwriteBackUrl !== undefined) {
                back.url = win.data.overwriteBackUrl;
                back.params.searchFields = {};
                win.searchValues = {};
            }

            win.colSettings = back.colSettings;
            win.searchCollapsed = back.searchCollapsed;
            back.params.extraParams =  win.data.extraParams ? win.data.extraParams : {};
            Ajax.call(back.url, back.params, function(response) {

                if(response.search && response.search.values) {
                    win.searchValues = angular.copy(response.search.values);
                }

                win.data     = response;
                win.url      = response.url;
                win.progress = false;
            }, null, win);
        };

        $this.setHistory = function(win,url,params) {

            // Don't add 'clone' actions to the history
            if(url.indexOf('clone') === -1) {
                win.history[win.history.length-1].colSettings = angular.copy(win.colSettings);
                win.history[win.history.length-1].searchCollapsed = angular.copy(win.searchCollapsed);
                win.history.push({url:angular.copy(url), params:angular.copy(params)});
            }
        };

        // Get the current page number
        $this.getCurrentPageNumber = function(url) {
            var page = 1;

            angular.forEach(Wins, function(value) {
                if(value.url === url) {
                    page = value.data.page || 1;
                }
            });

            return page;
        }

        // prepare share URL, add query string if
        $this.prepareShareUrl = function(url) {
            // get the current page number
            var page = $this.getCurrentPageNumber(url);
            // get the current rows per page limit
            var limit = $this.getPossibleRowsCount(url);

            var queryParams = {};

            // append page number if the page isn't the first page
            if(page > 1) {
                queryParams['page'] = page;
            }

            // append limit if the limit is one of the predefined limits [20,50,100, ...]
            if($this.pageLimits.indexOf(limit) != -1) {
                queryParams['limit'] = limit;
            }

            var queryString = Object.keys(queryParams).map(function(key) {
                return [key,queryParams[key]].join('=');
            }).join('&');

            // url will be used as a query string appended to main page url
            return queryString.length ? [url,queryString].join('&') : url;
        }

        $this.share = function(url) {

            // prepare query string params for share URL
            url = $this.prepareShareUrl(url);

            $mdToast.show({
                hideDelay   : 0,
                position    : 'top right',
                controller  : 'ToastShareCtrl',
                templateUrl : './partials/win/share',
                locals      : {url:url}
            });
        };

        $this.focus = function(win) {
            win.visible = true;
            win.zIndex = ++$this.zIndex;
        };

        // Window list data functions
        //========================================
        $this.sortTable = function(options) {
            var sort,
                win = options.win;

            win.progress = true;
            win.editor = false;

            if(win.data.sort && win.data.sort.column === options.column) {
                sort = win.data.sort.type === 'asc' ? 'desc' : 'asc';
            } else {
                sort = 'desc';
            }

            // Don't send empty fields
            if(win.data.search) {
                for(var i in win.data.search.values) {
                    if(!win.data.search.values[i] || win.data.search.values[i].length === 0) {
                        delete win.data.search.values[i];
                    }
                }
            }

            var params = {
                searchFields : win.data.search ? win.data.search.values : false,
                sort : {
                    column : options.column,
                    type   : sort
                },
                pageLimit : win.data.pageLimit,
                extraParams : win.data.extraParams ? win.data.extraParams : {}
            };

            $this.setHistory(win, win.url, params);
            Ajax.call(win.url, params, function(response) {
                win.data = response;
                win.progress = false;

            }, null, win);
        };

        $this.changePage = function(win,page) {
            win.progress = true;
            win.editor = false;
            win.data.page = page;

            // Don't send empty fields
            if(win.data.search) {
                for(var i in win.data.search.values) {
                    if(!win.data.search.values[i] || win.data.search.values[i].length === 0) {
                        delete win.data.search.values[i];
                    }
                }
            }

            var params = {
                searchFields : win.data.search ? win.data.search.values : false,
                sort         : win.data.sort,
                page         : win.data.page,
                pageLimit    : win.data.pageLimit,
                extraParams  : win.data.extraParams ? win.data.extraParams : {}
            };

            if(win.showDeletedUsers === true) {
                if (win.url === '/support/customer' || win.url === '/access/user') {
                    params.deleted = true;
                }
            }

            $this.setHistory(win, win.url, params);
            Ajax.call(win.url, params, function(response) {
                win.data = response;
                win.progress = false;
            }, null, win);
        };

        $this.pageLimits = ['20', '50', '100', '250', '500', '1000'];
        $this.changePageLimit = function(win) {
            win.progress = true;
            win.editor = false;

            var pageLimit       = win.data.pageLimit,
                storedRowsCount = $cookies.getObject('rowsCount') ? $cookies.getObject('rowsCount') : {};


            if($this.pageLimits.indexOf(win.data.pageLimit) === -1) {
                if(storedRowsCount[win.url]) {
                    delete storedRowsCount[win.url];
                    $cookies.putObject('rowsCount', storedRowsCount);
                }

                pageLimit = $this.getPossibleRowsCount();
            } else {
                storedRowsCount[win.url] = pageLimit;
                $cookies.putObject('rowsCount', storedRowsCount);
            }

            // Don't send empty fields
            if(win.data.search) {
                for(var i in win.data.search.values) {
                    if(!win.data.search.values[i] || win.data.search.values[i].length === 0) {
                        delete win.data.search.values[i];
                    }
                }
            }

            Ajax.call(
                win.url,
                {
                    searchFields : win.data.search ? win.data.search.values : false,
                    sort         : win.data.sort,
                    pageLimit    : pageLimit,
                    extraParams  : win.data.extraParams ? win.data.extraParams : {}
                },
                function(response) {
                    win.data = response;
                    win.progress = false;
                },
                null,
                win
            );
        };

        $this.hideCols = function(win) {
            for(var i = 0; i < win.data.columns.length; i++) {
                win.colSettings.hidden[i] = true;
            }
        };

        // Window search
        //========================================
        $this.search = {

            setOriginValue : function(originValue) {
                if(Array.isArray(originValue) && originValue.length) {
                    var newValue = [];

                    angular.forEach(originValue, function (value, key) {
                        newValue.push(value.toString());
                    });
                } else {
                    newValue = originValue;
                }

                return newValue;
            },

            valueChanged : function(origin, current) {
                return ! angular.equals(origin, current);
            },


            valuesChanged : function(origin, current) {
                angular.forEach(current, function(value, key) {
                    if(
                        (
                            origin
                            && !origin[key]
                            && !value
                        ) || (
                            !origin[key]
                            && value
                            && Array.isArray(value)
                            && !value.length
                        )
                    ) {
                        delete current[key];
                    }
                });

                return ! angular.equals(origin, current);
            },

            submit : function(win) {
                if(win.progress)
                    return false;

                win.progress = true;
                win.editor = false;

                // Don't send empty fields
                for(var i in win.data.search.values) {
                    if(!win.data.search.values[i] || win.data.search.values[i].length === 0) {
                        delete win.data.search.values[i];
                    }
                }

                win.searchValues = angular.copy(win.data.search.values);

                var params = {
                    searchFields : win.data.search.values,
                    sort         : win.data.sort,
                    pageLimit    : win.data.pageLimit,
                    extraParams  : win.data.extraParams ? win.data.extraParams : {}
                };

                $this.setHistory(win, win.url, params);
                Ajax.call(win.url, params, function(response) {
                    angular.forEach(response, function(value, key) {
                        if(key != 'search')
                            win.data[key] = value;
                    });
                    win.progress = false;
                }, null, win);
            },
            reset : function(win) {
                if(win.showDeletedUsers) {
                    delete win.showDeletedUsers;
                }

                if(win.progress)
                    return false;

                win.progress = true;
                win.editor = false;

                win.data.search = {};

                var params = {
                    sort      : win.data.sort,
                    pageLimit : win.data.pageLimit,
                    extraParams  : win.data.extraParams ? win.data.extraParams : {}
                };

                $this.setHistory(win, win.url, params);
                Ajax.call(win.url, params, function(response) {
                    win.data = response;
                    win.progress = false;
                }, null, win);
            },
            deletedRecords: function (win) {
                if(win.progress)
                    return false;

                win.progress = true;
                win.editor = false;

                win.data.search = {};

                win.showDeletedUsers = true;

                var params = {
                    deleted: win.showDeletedUsers,
                    sort      : win.data.sort,
                    pageLimit : win.data.pageLimit,
                    extraParams  : win.data.extraParams ? win.data.extraParams : {}
                };

                $this.setHistory(win, win.url, params);
                Ajax.call(win.url, params, function(response) {
                    win.data = response;
                    win.progress = false;
                }, null, win);
            },
            export : function(win) {
                if(win.progress)
                    return false;

                win.progress = true;

                // Don't send empty fields
                for(var i in win.data.search.values) {
                    if(!win.data.search.values[i] || win.data.search.values[i].length === 0) {
                        delete win.data.search.values[i];
                    }
                }

                Ajax.call(win.data.export.url,
                    {
                        searchFields : win.data.search.values,
                        sort         : win.data.sort,
                        pageLimit    : win.data.pageLimit,
                        fields       : win.data.search.values,
                        extraParams  : win.data.extraParams ? win.data.extraParams : {}
                    },
                    function(response) {
                        win.searchCollapsed = true;
                        win.data            = response;
                        win.url             = response.url;
                        win.progress        = false;
                    }, null, win);
            }
        };

        $this.updateContent = function(url, win, dataAttributes, filterData) {
            return Ajax.call(url, {
                    'filterString': filterData
                },
                function(response) {
                    if(!win.data)
                        win.data = {};

                    dataAttributes.forEach(function(attr) {
                        if(attr == 'url') {
                            win.url = response.url;
                        } else {
                            win.data[attr] = response[attr];
                        }
                    });
                },
                null,
                win
            ).$promise;
        };

        // Window Yii model functions
        //========================================
        $this.model = {

            create : function(win, url) {
                win.progress = true;
                win.editor   = false;

                if(url)
                    win.url = url;

                var params = {
                    searchFields : win.data.search && win.data.search.values ? win.data.search.values : false,
                    sort         : win.data.sort ? win.data.sort : false,
                    page         : win.data.page,
                    pageLimit    : $this.getPossibleRowsCount(win.url),
                    extraParams  : win.data.extraParams ? win.data.extraParams : {}
                };

                $this.setHistory(win, win.url, params); // without fields to update

                params.fields       = win.data.fields;
                params.selectedRows = win.data.selectedRows;
                var aUrl = (win.data.submitUrl) ? win.data.submitUrl : win.url;

                if (win.data.timeout && win.data.timeout.replicate && params.fields[win.data.timeout.replicate] && params.fields[win.data.timeout.replicate].value)
                    params.timeout = params.fields[win.data.timeout.replicate].value * win.data.timeout.multiplier > 10 ? params.fields[win.data.timeout.replicate].value * win.data.timeout.multiplier : null;
                else
                    params.timeout = win.data.timeout;

                function successCallback(response) {
                    if (win.url != response.url) {
                        win.url = response.url;
                        $this.setHistory(win, win.url, params);
                    }

                    if (response.shareUrl)
                        win.shareUrl = response.shareUrl;

                    if (response.showSearch)
                        win.searchCollapsed = false;

                    win.data = response;
                    win.progress = false;
                }

                var fileInput = document.querySelector('input[type="file"]#object_name_upload_field');

                if(fileInput && fileInput.files && fileInput.files.length === 1) {
                    // Find the label with the class "ng-binding" containing the text "Terminal"
                    var labels = document.querySelectorAll('label.ng-binding');
                    var nameInput = null;
                    var intervalInput = null;
                    var activeInput = null;
                    var terminalIdInput = null;

                    for (var key in labels) {
                        var label = labels[key];
                        var nextSibling = label.nextElementSibling;

                        if(!nextSibling) continue;

                        if (label.textContent.trim() === 'Screensaver Name') {
                            if (nextSibling.tagName === 'INPUT' && nextSibling.type === 'text') {
                                nameInput = nextSibling;
                                continue;
                            }
                        }

                        if (label.textContent.trim() === 'Screensaver Interval') {
                            if (nextSibling.tagName === 'MD-SELECT') {
                                intervalInput = nextSibling;
                                continue;
                            }
                        }

                        if (label.textContent.trim() === 'Screensaver Active') {
                            if (nextSibling.tagName === 'MD-SELECT') {
                                activeInput = nextSibling;
                                continue;
                            }
                        }

                        if (label.textContent.trim() === 'Screensaver Terminal ID') {
                            if (nextSibling.tagName === 'INPUT' && nextSibling.type === 'text') {
                                terminalIdInput = nextSibling;
                            }
                        }
                    }

                    var nameValue = nameInput && nameInput.value.trim().length ? nameInput.value.trim() : "";
                    var intervalValue = intervalInput && intervalInput.textContent.trim().length ? intervalInput.textContent.trim() : "";
                    var activeValue = activeInput && activeInput.textContent.trim().length ? activeInput.textContent.trim() : "";
                    var terminalIdValue = terminalIdInput && terminalIdInput.value.trim().length ? terminalIdInput.value.trim() : null;

                    if (!nameValue.length) {
                        alert('Please provide screensaver name.');
                        return;
                    }

                    if (!intervalValue.length) {
                        alert('Please select screensaver interval.');
                        return;
                    }

                    if (!activeValue.length) {
                        alert('Please check whether screensaver is active or not.');
                        return;
                    }

                    if (terminalIdValue == null) {
                        alert('Unable to determine Terminal ID. Cannot proceed with uploading a new screensaver.');
                        return;
                    } else {
                        if(aUrl.indexOf('/create') !== -1) {
                            aUrl += '/' + terminalIdValue;
                        }
                    }

                    var intervalMappings = {
                        'Never': 0,
                        '60 sec.': 60,
                        '2 min.': 120,
                        '5 min.': 300,
                        '8 min.': 480,
                        '10 min.': 600,
                        '12 min.': 720,
                        '15 min.': 900,
                        '20 min.': 1200,
                        '30 min.': 1800,
                        '45 min.': 2700,
                        '1 hr.': 3600,
                        '2 hr.': 7200,
                        '5 hr.': 18000,
                    };

                    var formData = new FormData();
                    formData.append('fields[name][value]', nameValue);
                    formData.append('fields[interval][value]', intervalMappings[intervalValue] || 0);
                    formData.append('fields[active][value]', activeValue === 'Yes' ? 1 : 0);
                    formData.append('fields[terminal_id][value]', terminalIdValue);
                    formData.append('object_name', fileInput.files[0]);

                    win.progress = true;

                    $http({
                        method: 'POST',
                        url: aUrl,
                        data: formData,
                        transformRequest: angular.identity,
                        headers: { 'Content-Type': undefined }
                    }).then(successCallback, function (response) {
                        if(response.status === 302) {
                            if (win && win.jsinterval) {
                                $interval.cancel(win.jsinterval);
                            }
                            $resource(response.headers('X-Redirect')).save({}, successCallback);
                            return;
                        }

                        // Handle errors
                        win.progress = false;
                        console.error(response);
                    });

                    return;
                }

                Ajax.call(aUrl, params, successCallback, null, win);
            },
            errorTooltip : function(errors) {
                if(errors) {
                    var e = '';

                    for (var i in errors) {
                        e += '<p>'+errors[i]+'</p>';
                    }
                    return e;
                }
            }
        };

        return $this;
    }])

;
