'use strict';

/* Controllers */
angular.module('baseapp.directives', [])
    .directive('dashboard', function() {
        return {
            restrict    : 'E',
            templateUrl : './partials/dashboard'
        };
    })
    .directive('grid', function() {
        return {
            restrict    : 'E',
            templateUrl : './partials/grid'
        };
    })
    .directive('win', function() {
        return {
            restrict    : 'E',
            templateUrl : './partials/win'
        };
    })
    .directive('winSearch', function() {
        return {
            restrict    : 'E',
            templateUrl : './partials/win/search'
        };
    })

    /**
     * Usage: cp-editable="<param>"
     * Param can be 'textarea' or input type attribute, e.g. 'text', 'number', 'email' etc.
     * Default 'text', if nothing specified - will render <input type="text">
     */
    .directive('cpEditable', function() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, element, attrs, ngModel) {
                if(attrs.cpEditable === 'false')
                    return false;

                var editElement = angular.element(attrs.cpEditable === 'textarea' ? '<textarea/>'  : '<input type="'+ attrs.cpEditable + '">');
                editElement.addClass('dynamic');
                var helpElement = angular.element('<div/>');
                helpElement.addClass('input-help');
                var helpText = attrs.tCharacterCount;

                // add attributes to cp editable element
                if(attrs.cpAttributes) {
                    var attributes = JSON.parse(attrs.cpAttributes);
                    for (var name in attributes) {
                        editElement[0].setAttribute(name, attributes[name]);
                    }
                }

                element.bind('click', function(e) {
                    e.stopPropagation();
                    if(e.target != this)
                        return;

                    editElement.val(ngModel.$viewValue || '');
                    helpElement.html(helpText + ' ' + editElement.val().length);

                    element.css('display','none');

                    element.parent().append(editElement);
                    editElement.focus();
                    element.parent().append(helpElement);

                    editElement.bind('input', function(e) {
                        helpElement.html(helpText + ' ' + editElement.val().length);
                    });

                    editElement.bind('blur', function(e) {
                        if (editElement[0].validity.valid) {
                            var value = editElement.val();
                            ngModel.$setViewValue(value ? value : null);
                            editElement.remove();
                            helpElement.remove();
                            element.css('display', 'block');
                        } else {
                            editElement.addClass('invalid');
                        }

                    });
                });
            }
        };
    })

    .directive('cpDialog', ['$compile', function($compile) {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {
                if(scope.directive) {
                    if(scope.directive === 'one-event-risk-limit') {
                        scope.title = scope.row.event + ' - ' + scope.row.market;
                        scope.fields.limitPercent.value = parseFloat(scope.fields.limitPercent.value);

                        var placeHolder = angular.element('<div class="current-percent" ng-class="{risk:(row.possibleLoss / fields.limitEur.value * 100 >= fields.limitPercent.value)}">{{row.possibleLoss / fields.limitEur.value * 100 | number:2}}%</div>');

                        $compile(placeHolder)(scope);
                        angular.element(element[0].querySelector('.form-content')).prepend(placeHolder);
                    }
                }
            }
        };
    }])

    .directive('cpDatetimePicker', ['mdcDateTimeDialog', '$timeout', function(mdcDateTimeDialog, $timeout) {
        return {
            restrict   : 'E',
            transclude : true,
            require    : 'ngModel',
            scope      : {
                ngModel : '='
            },
            template   : '<input ng-model="datetime" ng-change="updateDatetime()" flex><md-button ng-click="showPicker()" class="md-icon-button"><md-icon>access_time</md-icon></md-button>',
            link       : function(scope, element, attrs, ngModel) {

                scope.showPicker = function() {
                    mdcDateTimeDialog.show({
                        currentDate : moment(scope.datetime).isValid() ? scope.datetime : "",
                        format : 'YYYY-MM-DD HH:mm:ss',
                        autoOk : true,
                        okText: attrs.textOk,
                        cancelText: attrs.textCancel,
                        todayText: attrs.textToday
                    })
                    .then(function (date) {
                        scope.datetime = moment(date).format('YYYY-MM-DD HH:mm:ss');
                        ngModel.$setViewValue(scope.datetime);
                        // console.log('New Date / Time selected:', date);
                    }, function() {
                        // console.log('Selection canceled');
                    });
                };

                scope.updateDatetime = function() {
                    ngModel.$setViewValue(scope.datetime);
                };

                scope.$watch('ngModel',function(value) {
                    scope.datetime = value;
                });

                $timeout(function () {
                    scope.datetime = ngModel.$viewValue;
                });
            }
        };
    }])

    .directive('cpAutocomplete', ['$compile', '$timeout', function($compile, $timeout) {
        return {
            restrict: 'A',
            scope: true,
            link: function(scope, element, attrs) {
                if(attrs.cpAutocomplete === 'false')
                    return false;

                function getParentByTagName(node, tagname) {
                    var parent;
                    if (node === null || tagname === '') return;
                    parent  = node.parentNode;
                    tagname = tagname.toUpperCase();

                    while (parent.tagName !== "HTML") {
                        if (parent.tagName === tagname) {
                            return parent;
                        }
                        parent = parent.parentNode;
                    }

                    return parent;
                }


                element.bind('click', function(e) {
                    e.stopPropagation();

                    var editElement = angular.element('<div ng-controller="SearchCtrl as search">'+
                        '<md-autocomplete '+
                            'md-selected-item-change="row[col.key] = item.common_id; table.updateField(win,row.id,col.key,item.common_id,row[col.key + \'Old\'])" '+
                            'md-search-text="search.searchText" ' +
                            'md-items="item in search.querySearch(col.link, search.searchText)" ' +
                            'md-item-text="item.common_id.toString()" ' +
                            'md-autofocus ' +
                            'placeholder="Search"><span md-highlight-text="search.searchText">{{item.common_id}} - {{item.tag}}</span> '+
                        '</md-autocomplete></div>');

                    element.css('display','none');
                    element.parent().append(editElement);
                    $compile(editElement)(scope);

                    $timeout(function() {
                        var inputField = angular.element(editElement[0].getElementsByTagName('input')[0]);
                        inputField.focus();
                        inputField.bind('blur', function(e) {
                            $timeout(function() {
                                angular.element(getParentByTagName(document.getElementById(inputField[0].attributes['aria-owns'].value), 'md-virtual-repeat-container')).remove();
                                editElement.remove();
                                element.css('display', 'block');
                            }, 500);

                        });
                    },0);

                });
            }
        };
    }])

    /**
     * Usage: cp-number="<formatParams>"
     * formatParams is an object, which may contain fraction size (decimal), maxlength (fraction not considered), max (maximum value)
     */
    .directive('cpNumber', ['$filter', '$locale', function($filter, $locale) {
        return {
            restrict: 'A',
            scope: {
                format : '=cpNumber'
            },
            require: 'ngModel',
            link: function(scope, element, attrs, ngModelController) {
                var isCursorUp = false;
                var isCursorDown = false;
                var isBackspace = false;

                if(!scope.format)
                    scope.format = {};
                if(!scope.format.fraction)
                    scope.format.fraction = 0;

                element.bind('keydown', function(e) {
                    var code = e.keyCode || e.which;
                    isBackspace = code === 8;
                });

                ngModelController.$formatters.push(function(value)
                {
                    return $filter('number')(value, parseInt(scope.format.fraction));
                });

                ngModelController.$parsers.push(function(value)
                {
                    var cursorPosition = element[0].selectionStart,
                        pattern        = new RegExp('[^0-9'+ (scope.format.fraction ? $locale.NUMBER_FORMATS.DECIMAL_SEP : '') + ']','g'),
                        n              = value.replace(pattern, '').split($locale.NUMBER_FORMATS.DECIMAL_SEP);

                    if(scope.format.maxlength && n[0].length > scope.format.maxlength) {
                        n[0] = n[0].substring(0, scope.format.maxlength);
                    }

                    if(n[1] != undefined)
                        n[1] = $filter('rPad')(n[1].substring(0, scope.format.fraction), scope.format.fraction, '0');

                    if(scope.format.max && n[0] > scope.format.max)
                        n[0] = scope.format.max;

                    var nValue = parseFloat(n.join('.'));

                    n[0] = $filter('number')(n[0], 0);
                    var previousLength = isNaN(ngModelController.$modelValue) ? 0 : ngModelController.$modelValue.toString().split('.')[0].length; // if previous value of number is at max length we don't need to increment cursor position except if cursor position is on dot
                    if(!isCursorUp && !isBackspace && cursorPosition <= n[0].length && (n[0].length - 1) % 4 == 0 && (previousLength < scope.format.maxlength || n[0][cursorPosition-1] === '.')) {
                        cursorPosition++;
                        isCursorUp = true;
                    } else {
                        isCursorUp = false;
                    }

                    if(!isCursorDown && isBackspace && cursorPosition < n[0].length && (n[0].length + 1) % 4 == 0) {
                        cursorPosition--;
                        isCursorDown = true;
                    } else {
                        isCursorDown = false;
                    }

                    element.val(n[0] + (n[1] ? $locale.NUMBER_FORMATS.DECIMAL_SEP + n[1] : ''));
                    element[0].selectionStart = cursorPosition;
                    element[0].selectionEnd   = cursorPosition;
                    element[0].focus();

                    ngModelController.$setViewValue(element.val());

                    return nValue;
                });
            }
        };
    }])

    .directive('contenteditable', function() {
    return {
        restrict: "A",
        require: "ngModel",
        link: function(scope, element, attrs, ngModel) {

            function read() {
                ngModel.$setViewValue(element.html());
            }

            ngModel.$render = function() {
                element.html(ngModel.$viewValue || "");
            };

            element.bind('blur keyup change', function(e) {
                scope.$apply(read);
            });

            element.bind('keydown', function(e) {
                var code = e.keyCode || e.which;
                if(code === 13) {
                    e.preventDefault();
                    if(element.parent().next().length) {
                        element.parent().next().children()[0].focus();
                    }
                }
            });
        }
    };
    })
    .directive('tinymceEditor', function () {
        return {
            restrict: 'E',
            require: 'ngModel',
            scope: true,
            template: '<textarea class="editor"></textarea>',
            link: function (scope, element, attrs, ngModel) {

                var textarea = element.find('textarea');
                ngModel.$render = function () {
                    textarea.val(ngModel.$viewValue);
                };

                // create tinymc plugin to change text style (we user addToggleButton method to create button which can have active/inactive state)
                tinymce.PluginManager.add('stylebuttons', function(editor) {
                    ['h1', 'h2', 'h3', 'p', 'pre', 'code' ].forEach(function(name){
                        editor.ui.registry.addToggleButton("style-" + name, {
                            tooltip: "Toggle " + name,
                            text: name,
                            onAction: function() { editor.execCommand('mceToggleFormat', true, name); },
                            onSetup: function (buttonApi) {
                                var editorEventCallback = function (eventApi) {
                                    buttonApi.setActive(eventApi.element.nodeName.toLowerCase() === name);
                                };
                                editor.on('NodeChange', editorEventCallback);
                                return function (buttonApi) {
                                    editor.off('NodeChange', editorEventCallback);
                                }
                            }
                        });
                    });
                });

                setTimeout(function() {
                    tinymce.init({
                        selector: 'textarea.editor',
                        plugins: "stylebuttons",
                        menubar : false,
                        toolbar: 'undo redo | style-h1 style-h2 style-h3 style-p style-pre style-code | bold italic | bullist | link',
                        setup: function (inst) {
                            inst.on('change', function () {
                                ngModel.$setViewValue(inst.getBody().innerHTML);
                            });
                        }
                    });
                },0);


            }
        };
    })

    .directive('cpPagination', function() {
        return {
            restrict: 'E',
            scope: {
                page: '@',
                pageSize: '@',
                calculatedPagesCount: '@',
                total: '@',
                toPageLabel: '@',
                changePage: '&'
            },
            template:
            '<div ng-if="show" layout="row" layout-align="center">' +
            '<ul> ' +
            '<li ng-show="first" ng-click="action(1)">' +
            '<md-icon class="material-icons">fast_rewind</md-icon>' +
            '</li>' +
            '<li ng-class="{active:item==page}" ng-click="action(item)" ng-repeat="item in list">{{item}}</li>' +
            '<li ng-show="last" ng-click="action(pageCount)">' +
            '<md-icon class="material-icons">fast_forward</md-icon>' +
            '</li>' +
            '</ul>' +
            '<form ng-submit="action(toPage)" name="goToForm" layout="row" layout-align="center center" novalidate>' +
            '<md-input-container class="md-accent">' +
            '<label>{{toPageLabel}}</label>' +
            '<input ng-model="toPage" name="toPage" type="number" min="1" max="{{pageCount}}" ng-required="true" >' +
            '</md-input-container>' +
            '<span>' +
            '<md-button ng-disabled="goToForm.$invalid" type="submit" class="md-icon-button md-accent md-raised"><md-icon>skip_next</md-icon></md-button></span>' +
            '</form>' +
            '</div>',
            link: function (scope, element, attrs) {
                scope.first     = false;
                scope.last      = false;
                scope.page      = parseInt(scope.page);
                scope.list      = [];

                if(isNaN(scope.calculatedPagesCount)) {
                    scope.pageCount = Math.ceil(parseInt(scope.total) / parseInt(scope.pageSize));
                }else{
                    scope.pageCount = scope.calculatedPagesCount;
                }

                var limit = 5,
                    start = scope.page - limit,
                    end   = scope.page + limit;

                if(start < 1) {
                    start = 1;
                    end = start + limit * 2;
                }
                if(end > scope.pageCount) {
                    end = scope.pageCount;
                    start = end - limit * 2;
                    if(start < 1)
                        start = 1;
                }
                if(start > 1)
                    scope.first = true;
                if(end < scope.pageCount)
                    scope.last = true;

                for (var i = start; i <= end; i++) {
                    scope.list.push(i);
                }

                scope.show = scope.pageCount > 1;

                scope.action = function(page) {
                    if (scope.page === page) {
                        return;
                    }

                    scope.changePage({page:page});
                };
            }
        };
    })

    .directive('cpTreeview', ['WinFn','Ajax', function(WinFn,Ajax) {
        return {
            restrict: 'E',
            scope: {
                items: '=',
                showChildren: '@',
                win: '=',
                app: '=',
                filter: '='
            },
            template:
            '<ul> ' +
            '<li ng-repeat="item in items">' +
                '<div layout="row"  ng-click="showLink(item)">' +
                '<span flex ng-class="{action:item.action}">{{item.title}}</span>' +
                '<md-icon ng-if="item.items">{{item.showChildren ? \'keyboard_arrow_up\' : \'keyboard_arrow_down\'}}</md-icon>' +
            '</div>' +
                '<cp-treeview ng-if="item.items" ng-show="item.showChildren" items="item.items" app="app" win="win" warnmsg="{{warnmsg}}" filter="filter"><cp-treeview>' +
            '</li>' +
            '</ul>',
            link: function (scope, element, attrs) {
                scope.warnmsg = attrs.warnmsg;
                scope.showChildren = false;

                scope.showLink = function(item) {
                    if(item.action && item.action.link) {
                        var execute = true;
                        if(scope.win.data && scope.win.data.selectedRows.length > 0) {
                            execute = confirm(scope.warnmsg);
                        }
                        if(execute) {
                            WinFn.updateContent(item.action.link, scope.win, ['template','rows', 'columns', 'message','selectedRows','fields','url','search','submitUrl','actions'], scope.filter);
                        }
                    } else {
                        item.showChildren = !item.showChildren;
                    }
                };
            }
        };
    }])

    .directive('cpTreeviewFilter', ['WinFn','Ajax', function(WinFn,Ajax) {
        return {
            restrict: 'E',
            filterProcessing: false,
            scope: {
                win: '=',
                filter: '='
            },
            template: '' +
            '<div class="">' +
            '   <md-content layout="row" flex>' +
            '           <md-input-container flex class="md-icon-float md-block">' +
            '               <label>Search</label>' +
            '               <input ng-model="filter">' +
            '           </md-input-container>' +
            '           <md-input-container>' +
            '               <md-button  ng-click="fnFilter()" class="md-icon-button" ng-disabled="filterProcessing">' +
            '                   <md-icon>search</md-icon>' +
            '               </md-button>' +
            '           </md-input-container>' +
            '   </md-content>' +
            '       <md-progress-linear ng-if="filterProcessing" md-mode="indeterminate"></md-progress-linear>' +
            '</div>',
            link: function (scope, element, attrs) {
                scope.fnFilter = function() {
                    this.filterProcessing = true;
                    var self = this;
                    WinFn.updateContent(scope.win.data.actions.sidenav.refresh, scope.win, ['actions'], scope.filter).then(function() {
                        self.filterProcessing = false;
                    });
                };
            }
        }
    }])

    .directive('cpContentTreeview', function() {
        return {
            restrict: 'E',
            scope: {
                items: '='
            },
            template:
            '<md-list flex> ' +
                '<md-list-item ng-repeat="(key, value) in items" class="md-2-line md-long-text">' +
                    '<div class="md-list-item-text" layout="column">' +
                        '<h3 ng-if="!isNumber(key)">{{ key }}</h3>' +
                        '<p ng-if="isString(value)">{{ value }}</p>' +
                        '<p ng-if="!value"> - </p>' +
                        '<cp-content-treeview ng-if="isArray(value) || isObject(value)" items="value" ng-style="isNumber(key)  && {\'margin-left\':\'-16px\'}"><cp-content-treeview>' +
                    '</div>' +
                '</md-list-item>' +
            '</md-list>',
            link: function (scope, element, attrs) {

                scope.isNumber = angular.isNumber;
                scope.isString = angular.isString;
                scope.isArray  = angular.isArray;
                scope.isObject = angular.isObject;

            }
        };
    })

    .directive('cpSelect', function() {
        return {
            restrict: "A",
            require: "ngModel",
            link: function(scope, element, attrs, ngModel) {

                element.bind('click', function(e) {
                    if(element[0].attributes.multiple)
                        return;

                    if(element[0].attributes['cp-select'].value && element[0].attributes['cp-select'].value === 'true')
                        return;

                    scope.$watch(function() {
                        return element.attr('aria-owns');
                    }, function(dropdownId) {
                        angular.element(document.getElementById(dropdownId).getElementsByTagName('md-option')).on('click', function(e) {

                            var el = e.target !== this ? angular.element(e.target).parent()[0] : e.target;

                            if(ngModel.$viewValue === el.attributes.value.value) {
                                setTimeout(function() {
                                    ngModel.$setViewValue(null);
                                    ngModel.$render();
                                    element[0].parentElement.classList.remove('md-input-focused');
                                }, 0);
                            }
                        });
                    });
                });
            }
        };
    })

    .directive('draggable', ['Grid', function(Grid) {
        return function(scope, element) {
            var dragEnded = null;
            var el = element[0],
                dragIcon = document.createElement('img');
            dragIcon.src = 'images/drag-icon.png';

            el.addEventListener(
                'dragstart',
                function(e) {
                    e.stopPropagation();
                    dragEnded = 0;
                    e.dataTransfer.effectAllowed = 'move';
                    e.dataTransfer.setDragImage(dragIcon, 32, 32);
                    e.dataTransfer.setData('id', el.dataset.id);
                    e.dataTransfer.setData('position', el.dataset.position);

                    // Timeout used because of bug in Chrome
                    // Can't manipulate DOM in 'dragstart'
                    setTimeout(function() {
                        if(dragEnded === 0) {
                            Grid.placeholder = '';
                            Grid.visible = true;
                            scope.$apply();
                        }
                    }, 10);

                    return false;
                },
                false
            );

            el.addEventListener(
                'dragend',
                function(e) {
                    dragEnded = 1;
                    Grid.placeholder = '';
                    Grid.visible = false;
                    scope.$apply();
                },
                false
            );
        };
    }])

    .directive('droppable', ['Wins', 'WinFn', 'Grid', function(Wins, WinFn, Grid) {
        return {
            link: function(scope, element) {
                var el = element[0];

                el.addEventListener(
                    'dragover',
                    function(e) {
                        e.preventDefault();
                    },
                    false
                );
                el.addEventListener(
                    'dragenter',
                    function(e) {
                        Grid.placeholder = e.target.dataset.area;
                        scope.$apply();
                    },
                    false
                );

                el.addEventListener(
                    'drop',
                    function(e) {
                        e.preventDefault();
                        var idx,
                            id = parseInt(e.dataTransfer.getData('id'));

                        for(var i = 0; i < Wins.length; i++) {
                            if(Wins[i] && Wins[i].id === id) {
                                idx = i;
                                break;
                            }
                        }

                        Wins[idx].position = Grid.placeholder;
                        Wins[idx].visible  = true;
                        Wins[idx].zIndex   = ++WinFn.zIndex;

                        scope.$apply();
                    },
                    false
                );
            }
        };
    }])
    .directive('cpTable', ['WinFn','Ajax', function(WinFn,Ajax) {
        return {
            restrict: 'E',
            link: function (scope, element) {
                element.bind('scroll', function() {
                    angular.element(element[0].getElementsByClassName('fixed')).css('left', element[0].scrollLeft + 'px');
                });
                scope.$on('scrollVirtualRepeat', function() {
                    angular.element(element[0].getElementsByClassName('fixed')).css('left', element[0].scrollLeft + 'px');
                });
            }
        };
    }])

    .directive('resize', ['Fn', function(Fn) {
        return {
            restrict: 'A',
            require: '^mdVirtualRepeatContainer',
            link: function(scope, element, attributes, mdVirtualRepeatContainer) {
                var scroller = angular.element(element[0].getElementsByClassName('md-virtual-repeat-scroller'))

                scroller.bind('scroll', Fn.debounce(function() {
                    mdVirtualRepeatContainer.updateSize();
                    scope.$emit('scrollVirtualRepeat');
                }, 200));

                element.ready(function () {
                    scroller.triggerHandler('scroll');
                });
            }
        };
    }])
;
